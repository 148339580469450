
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { ElTable } from "element-plus";
import moment from "moment";
import JwtService from "@/core/services/JwtService";

// import { ElButton, ElDialog } from 'element-plus'
// import { CircleCloseFilled } from '@element-plus/icons-vue'

export default defineComponent({
  name: "add-client-modal",
  props: {
    id: { required: true },
  },
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    const supplier_data = ref<Array<Supplier>>([]);
    const scno_data = ref([]);
    const tableData = ref<Array<any>>([]);
    const allocationList = ref<Array<any>>([]);
    const stockDetailList = ref<Array<any>>([]);
    const stockProductData = ref<Array<any>>([]);
    const dialogSelectLocationTableVisible = ref(false);
    const dialogNewAllocationTableVisible = ref(false);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const stockDetailListRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<Array<any>>([]);
    const customer_branch_data = ref<Array<any>>([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const myData = ref<Array<any>>([]);
    const isIGST = ref(1);
    const router = useRouter();
    const soDetailIdVal = ref(0);

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    const staticTableFileds = [
      {
        is_static: true,
        id: 1,
        display_name: "Sub Total :",
        amount: "",
        igst: "",
        cgst: "",
        totalAmount: "",
      },
      {
        is_static: true,
        id: 2,
        display_name: "Total :",
        amount: "",
        igst: "",
        cgst: "",
        totalAmount: "",
      },
      {
        is_static: true,
        id: 3,
        display_name: "Round UP :",
        amount: "",
        igst: "",
        cgst: "",
        totalAmount: "",
      },
      {
        is_static: true,
        id: 4,
        display_name: "Grand Total :",
        amount: "",
        igst: "",
        cgst: "",
        totalAmount: "",
      },
    ];

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Sales Order", []);
      getTableData();
    });

    const formData = ref({
      client_select: "",
      customer_branch_select: "",
      date: moment().format("YYYY-MM-DD"),
      po_no: "",
      po_date: "",
      scno_select: [],
      remark: "",
    });

    const resetFomData = () => {
      formData.value = {
        client_select: "",
        customer_branch_select: "",
        date: moment().format("YYYY-MM-DD"),
        po_no: "",
        po_date: "",
        scno_select: [],
        remark: "",
      };
      myData.value = [];
    };

    const rules = ref({
      client_select: [
        {
          required: true,
          message: "Client is required",
          trigger: "change",
        },
      ],
      date: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
      customer_branch_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",
        },
      ],
      scno_select: [
        {
          required: true,
          message: "SC No is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      debugger;
      let scDetailsIds: any = [];
      for (let item of myData.value) {
        if (!item.is_static) {
          scDetailsIds.push(item.sc_detail_id);
        }
      }

      if (scDetailsIds.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      let request_body = {
        so_date: moment(data.date).format("YYYY-MM-DD"),
        customer_po_no: data.po_no,
        customer_po_date: moment(data.po_date).format("YYYY-MM-DD"),
        sc_detail_ids: scDetailsIds,
        remark: data.remark,
        user_id: user.user_id,
      };

      console.log("request Body", request_body);
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_SALES_ORDER, request_body)
          .then(({ data, is_error, status }) => {
            debugger;
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-sales-order-listing" });
                emit("refreshList");
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getTableData() {
      let user = JSON.parse(JwtService.getToken());
      let params = {
        sales_order_id: props?.id,
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_SALSE_ORDER_DETAILS, params)
        .then(({ data }) => {
          debugger;
          //   data.map((enquiry) => {
          //     enquiry.offered_qty = enquiry.offered_qty + " " + enquiry.uom_name;
          //   });
          tableData.value = data?.product_details;
          console.log("tableData", tableData.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const onContinue = () => {
      debugger;
      if (multipleSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one Offer",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        myData.value = multipleSelection.value;
        myData.value = myData.value.concat(staticTableFileds);
        dialogSelectLocationTableVisible.value = false;
        calculateTableData();
        console.log("selected values", myData.value);
      }
    };

    const calculateTableData = () => {
      let igstSubTotal = 0;
      let cgstSubTotal = 0;
      let sgstSubTotal = 0;
      let amountSubTotal = 0;
      let totalSubtotal = 0;
      let grandTotal = 0;
      myData.value.map((item) => {
        if (!item.is_static) {
          amountSubTotal =
            parseFloat(item.product_taxable_amount) + amountSubTotal;
          igstSubTotal = parseFloat(item.igst_amount) + igstSubTotal;
          cgstSubTotal = parseFloat(item.cgst_amount) + cgstSubTotal;
          sgstSubTotal = parseFloat(item.sgst_amount) + sgstSubTotal;
          totalSubtotal = parseFloat(item.product_total_amount) + totalSubtotal;
        }
      });

      grandTotal = Math.round(totalSubtotal);
      let roundUP = totalSubtotal - grandTotal;

      myData.value.map((item) => {
        if (item.is_static) {
          if (item.id == 1) {
            item.amount = parseFloat(amountSubTotal.toString()).toFixed(2);
            item.igst = parseFloat(igstSubTotal.toString()).toFixed(2);
            item.cgst = parseFloat(cgstSubTotal.toString()).toFixed(2);
            item.sgst = parseFloat(sgstSubTotal.toString()).toFixed(2);
            item.totalAmount = parseFloat(totalSubtotal.toString()).toFixed(2);
          } else if (item.id == 2) {
            item.totalAmount = parseFloat(totalSubtotal.toString()).toFixed(2);
          } else if (item.id == 3) {
            item.totalAmount = parseFloat(roundUP.toString()).toFixed(2);
          } else if (item.id == 4) {
            item.totalAmount = parseFloat(grandTotal.toString()).toFixed(2);
          }
        }
      });
    };

    const handleSelectionChange = (val) => {
      multipleSelection.value = val;
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogSelectLocationTableVisible.value = false;
    };

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCustomerBranchList() {
      let values = {
        company_id: formData.value["client_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getSCData() {
      let values = {
        customer_company_id: formData.value["client_select"],
        customer_company_branch_id: formData.value["customer_branch_select"],
      };
      await store
        .dispatch(
          ActionsFi.CUST_GET_PENDING_SALES_CONTRACT_LISTING_DATA,
          values
        )
        .then(({ data }) => {
          debugger;
          scno_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDilogue = () => {
      dialogSelectLocationTableVisible.value = true;
    };

    const allocateStock = async (soDetailId) => {
      dialogSelectLocationTableVisible.value = true;
      let user = JSON.parse(JwtService.getToken());
      soDetailIdVal.value = soDetailId;
      let params = {
        so_detail_id: soDetailId,
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_STOCK_ALLOCATION_LIST, params)
        .then(({ data }) => {
          allocationList.value = data;
          console.log("allocationList", tableData.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const allocateNewStock = async () => {
      dialogNewAllocationTableVisible.value = true;
      let user = JSON.parse(JwtService.getToken());
      let params = {
        so_detail_id: soDetailIdVal.value, //soDetailId,
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PRODUCT_STOCK_DETAILS, params)
        .then(({ data }) => {
          stockProductData.value = data;
          stockDetailList.value = data?.product_details;
          let newAllocationList = stockDetailList.value.map((item) => {
            return { ...item, isCheck: false, allocated_quantity: "" };
          });
          stockDetailList.value = newAllocationList;
          console.log("stockDetailList", stockDetailList.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const onCheckAndInput = (editedRow) => {
      if (!/^\d*\.?\d*$/.test(editedRow.allocated_quantity)) {
        editedRow.allocated_quantity = editedRow.allocated_quantity.slice(
          0,
          -1
        );
        return;
      }
      if(parseFloat(editedRow.allocated_quantity) > 
            parseFloat(editedRow.stock_quantity)){
        Swal.fire({
            text: "Please enter allocated qty less than or equal to stock qty",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          editedRow.allocated_quantity = '';
          return;
      }
    };

    const saveAllocation = async () => {
      var user = JSON.parse(JwtService.getToken());
      debugger;

      for (let data of stockDetailList.value) {
        if (data.isCheck && !data.allocated_quantity) {
          Swal.fire({
            text: "Please enter allocated qty.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loading.value = false;
          return;
        }
      }

      let stockData: any = [];
      for (let data of stockDetailList.value) {
        if (data.isCheck && data.allocated_quantity)
          stockData.push({
            stock_detail_id: data.stock_detail_id,
            allocated_quantity: data.allocated_quantity,
          });
      }

      if (stockData.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      let request_body = {
        user_id: user.user_id,
        so_detail_id: soDetailIdVal.value,
        stock_data: stockData,
      };

      console.log("request Body", request_body);
      try {
        await store
          .dispatch(ActionsFi.CUST_STOCK_ALLOCATION_SAVE, request_body)
          .then(({ data, is_error, status }) => {
            debugger;
            loading.value = false;
            console.log(data);
            if (is_error == false) {
              setTimeout(() => {
                loading.value = false;

                Swal.fire({
                  text: "Form has been successfully submitted!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-sales-order-listing" });
                  emit("refreshList");
                });
              }, 2000);
            } else {
              loading.value = false;
              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      tableData,
      isIGST,
      myData,
      getCompanyList,
      getCustomerBranchList,
      resetFomData,
      supplier_data,
      customer_branch_data,
      dialogSelectLocationTableVisible,
      dialogNewAllocationTableVisible,
      multipleTableRef,
      stockDetailListRef,
      multipleSelection,
      openDilogue,
      handleSelectionChange,
      onCancle,
      getSCData,
      scno_data,
      getTableData,
      onContinue,

      allocateStock,
      allocationList,
      stockDetailList,
      allocateNewStock,
      onCheckAndInput,
      saveAllocation,
      stockProductData,
    };
  },
});
